import { useState, useEffect, useCallback, useRef } from 'react';

import constants from '../config/constants';
import { updateUserSettings } from '../utils/api';
import { usePermissions } from '../utils/PermissionsProvider';

import useErrorHandler from './useErrorHandler';

const useUserApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { getAssumedRole } = usePermissions();

  const cancelRequest = useRef<boolean>();

  useEffect(() => {
    cancelRequest.current = false;
    return () => {
      cancelRequest.current = true;
    };
  }, []);

  const updateWelcomeMessageStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const settingsVariable =
        getAssumedRole() === constants.assumedRoles.DRIVER ? 'driverSettings' : 'managerSettings';
      const body = {
        userSettings: {
          [settingsVariable]: {
            welcomeMessageSeen: true,
          },
        },
      };
      await updateUserSettings(body);
    } catch (error) {
      errorHandler(error);
      return false;
    }
    if (!cancelRequest.current) {
      setIsLoading(false);
    }
    return true;
  }, [errorHandler, getAssumedRole]);

  const updateLanguage = useCallback(
    async (lang) => {
      setIsLoading(true);
      try {
        const body = {
          userSettings: {
            language: lang,
          },
        };
        await updateUserSettings(body);
      } catch (error) {
        errorHandler(error);
        return false;
      }
      if (!cancelRequest.current) {
        setIsLoading(false);
      }
      return true;
    },
    [errorHandler]
  );

  return {
    updateWelcomeMessageStatus,
    updateLanguage,
    isLoading,
  };
};

export default useUserApi;
