import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { useAuth0 } from '../../utils/Auth0Provider';
import { Welcome } from '../Illustrations';
import ActionDialog from '../ActionDialog';
import useUserApi from '../../hooks/useUserApi';
import { getUserSettings } from '../../utils/api';
import { usePermissions } from '../../utils/PermissionsProvider';
import constants from '../../config/constants';

import TourGuide from './TourGuide';

const ImageWrapper = styled.div`
  > svg {
    max-width: 15rem;
  }
`;

function WelcomeDialog() {
  const { t } = useTranslation('dashboard');
  const [showTour, setShowTour] = useState(false);
  const { user } = useAuth0();
  const { hasAssumedRole, getAssumedRole } = usePermissions();
  const name = user?.given_name && user?.family_name ? `${user?.given_name} ${user?.family_name}` : null;

  const assumedRole = useMemo(() => getAssumedRole(), [getAssumedRole]);

  // in case multiple users use the same laptop, we use the user id to differentiate them
  const varNameFromLocalStorage = useMemo(
    () => `welcomeMessageSeen-${assumedRole}-${user?.sub}`,
    [assumedRole, user?.sub]
  );
  const statusFromLocalStorage = localStorage.getItem(varNameFromLocalStorage);

  const [dialogOpen, setDialogOpen] = useState(statusFromLocalStorage !== 'true');

  const { data, error } = useSWR(statusFromLocalStorage === null ? 'getUserSettings' : null, getUserSettings);

  const { updateWelcomeMessageStatus } = useUserApi();

  useEffect(() => {
    const welcomeMessageSetting =
      assumedRole === constants.assumedRoles.DRIVER ? data?.driverSettings : data?.managerSettings;
    const statusFromDb = welcomeMessageSetting?.welcomeMessageSeen;
    if (!error && welcomeMessageSetting && !statusFromLocalStorage) {
      setDialogOpen(!statusFromDb);
      localStorage.setItem(varNameFromLocalStorage, statusFromDb?.toString() || '');
    }
  }, [error, data, assumedRole, varNameFromLocalStorage, statusFromLocalStorage]);

  const onClose = async () => {
    const response = await updateWelcomeMessageStatus();
    if (response) {
      localStorage.setItem(varNameFromLocalStorage, 'true');
    }
    setDialogOpen(false);
  };

  const handleShowTour = () => {
    setShowTour(true);
  };

  const onFinishTour = () => {
    setShowTour(false);
  };
  const dialogText = hasAssumedRole(constants.assumedRoles.DRIVER)
    ? t('welcome dialog content driver')
    : t('welcome dialog content fleet manager');
  return (
    <>
      {!error && (
        <ActionDialog
          content={dialogText}
          disableBackdropClick
          image={
            <ImageWrapper>
              <Welcome />
            </ImageWrapper>
          }
          confirmationButtonText={t('start tour guide')}
          closeBtnText={t('go to dashboard')}
          maxWidth="sm"
          open={dialogOpen}
          title={t('welcome', { name })}
          handleClose={() => onClose()}
          handleConfirmation={async () => {
            await onClose();
            handleShowTour();
          }}
          showCloseIcon
          buttonDisabled={!hasAssumedRole(constants.assumedRoles.DRIVER)}
          disableFullscreen
          fullLengthButton={false}
        />
      )}
      <TourGuide showTour={showTour} onFinishTour={onFinishTour} />
    </>
  );
}

export default WelcomeDialog;
